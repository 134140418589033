"use client"

import { useEffect, useState } from "react"
import { useSearchParams } from "next/navigation"

export const useHashSearchParams = () => {
  const searchParams = useSearchParams()
  const [params, setParams] = useState<URLSearchParams>(new URLSearchParams())

  useEffect(() => {
    const updateParams = () => {
      const hash = window.location.hash
      if (hash.startsWith("#?") || hash.startsWith("#/?")) {
        const queryString = hash.substring(2) // Remove '#?'
        setParams(new URLSearchParams(queryString))
      } else {
        setParams(searchParams)
      }
    }

    updateParams()

    window.addEventListener("hashchange", updateParams)

    return () => {
      window.removeEventListener("hashchange", updateParams)
    }
  }, [searchParams])

  return params
}
