"use client"

import * as React from "react"
import { useSignIn, useSignUp } from "@clerk/nextjs"
import { type OAuthStrategy } from "@clerk/types"

import { catchClerkError } from "@/lib/utils"
import { useHashSearchParams } from "@/hooks/use-hash-search-params"
import { Button } from "@/components/ui/button"
import { Icons } from "@/components/icons"

import { LastProviderBadge } from "./last-provider-badge"

const oauthProviders = [
  { name: "Google", strategy: "oauth_google", icon: "google" },
  { name: "Facebook", strategy: "oauth_facebook", icon: "facebook" },
  // { name: "Discord", strategy: "oauth_discord", icon: "discord" },
] satisfies {
  name: string
  icon: keyof typeof Icons
  strategy: OAuthStrategy
}[]

type OAuthProvidersProps = {
  mode: "signup" | "signin"
}

export function OAuthProviders({ mode }: OAuthProvidersProps) {
  const hashSearchParams = useHashSearchParams()
  const redirectUrl = hashSearchParams.get("redirect_url")
  const [isLoading, setIsLoading] = React.useState<OAuthStrategy | null>(null)
  const { signIn, isLoaded: signInLoaded } = useSignIn()
  const { signUp, isLoaded: signUpLoaded } = useSignUp()

  const REDIRECT_URLS = {
    "/ingreso": "/",
    "/registro": "/usuario/tus-anuncios",
    "/registro/particular": "/membership-callback?membershipPlan=individual",
    "/registro/profesional": "/membership-callback?membershipPlan=professional",
    "/registro/empresarial": "/membership-callback?membershipPlan=enterprise",
  }
  const REDIRECT_URL_DEFAULT = "/"

  async function oauthSignIn(provider: OAuthStrategy) {
    if (!signInLoaded || !signUpLoaded) return null
    try {
      localStorage.setItem("loginAttempProvider", provider)
      setIsLoading(provider)
      const finalRedirectUrl =
        REDIRECT_URLS[window.location.pathname as keyof typeof REDIRECT_URLS] ||
        redirectUrl ||
        REDIRECT_URL_DEFAULT
      if (mode === "signin") {
        await signIn.authenticateWithRedirect({
          strategy: provider,
          redirectUrl: `/sso-callback?redirectUrl=${encodeURIComponent(
            finalRedirectUrl
          )}`,
          redirectUrlComplete: redirectUrl ?? finalRedirectUrl,
        })
      } else if (mode === "signup") {
        await signUp.authenticateWithRedirect({
          strategy: provider,
          redirectUrl: `/sso-callback?redirectUrl=${encodeURIComponent(
            finalRedirectUrl
          )}`,
          redirectUrlComplete: redirectUrl ?? finalRedirectUrl,
        })
      }
    } catch (error) {
      catchClerkError(error)
    } finally {
      setIsLoading(null)
    }
  }

  return (
    <div className="mb-3 grid grid-cols-1 gap-8 py-4 sm:grid-cols-2 sm:gap-4">
      {oauthProviders.map((provider) => {
        const Icon = Icons[provider.icon]
        return (
          <Button
            aria-label={`Regístrate con ${provider.name}`}
            key={provider.strategy}
            variant="outline"
            className="relative w-full bg-background sm:w-auto"
            onClick={() => void oauthSignIn(provider.strategy)}
            disabled={isLoading !== null}
          >
            {localStorage.getItem("lastLoginProvider") === provider.strategy ? (
              <LastProviderBadge className="absolute -top-6" />
            ) : null}
            {isLoading === provider.strategy ? (
              <Icons.spinner
                className="mr-2 size-4 animate-spin"
                aria-hidden="true"
              />
            ) : (
              <Icon className="mr-2 size-4" aria-hidden="true" />
            )}
            {provider.name}
          </Button>
        )
      })}
    </div>
  )
}
