import { MembershipPlanEnum } from "@/db/schemas/enums"
import validator from "validator"
import { z } from "zod"

export const userPrivateMetadataSchema = z.object({
  role: z.enum(["user", "admin", "super_admin"]),
  stripePriceId: z.string().optional().nullable(),
  stripeSubscriptionId: z.string().optional().nullable(),
  stripeCustomerId: z.string().optional().nullable(),
  stripeCurrentPeriodEnd: z.string().optional().nullable(),
})

export const userPublicMetadataSchema = z.object({
  membershipPlan: MembershipPlanEnum.nullable(),
  canImport: z.boolean().optional(),
  onboardingStatus: z.enum(["created", "completed"]).optional(),
})

export const userUnsafeMetadataSchema = z.object({
  showAvatar: z.boolean(),
  bio: z.string().max(450).optional(),
  phone: z.string().refine(validator.isMobilePhone, {
    message: "Debes ingresar un número de teléfono válido",
  }),
  hasAcceptedMarketing: z.boolean().optional(),
  propertyContactLeads: z.boolean().optional(),
  propertyVisitsMilestones: z.boolean().optional(),
})

export const organizationPublicMetadataSchema = z.object({
  email: z.string().email({
    message: "Por favor, introduce un email válido",
  }),
  phone: z.string().refine(validator.isMobilePhone, {
    message: "Debes ingresar un número de teléfono válido",
  }),
  whatsAppPhone: z
    .string()
    .refine(validator.isMobilePhone, {
      message: "Debes ingresar un número de teléfono válido",
    })
    .optional(),
  address: z.string().optional(),
  description: z.string().max(450).optional(),
  websiteUrl: z
    .string()
    .url({
      message: "Por favor, introduce una URL válida",
    })
    .optional()
    .or(z.literal("")),
  ruc: z
    .string()
    .regex(/^((?!(10))[0-9]{11})$/, {
      message: "Por favor, introduce un RUC válido",
    })
    .optional()
    .or(z.literal("")),
})
