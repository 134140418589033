import validator from "validator"
import { z } from "zod"

import { organizationPublicMetadataSchema } from "@/lib/validations/metadata"

export const signUpSchema = z.object({
  email: z.string().email({
    message: "Por favor, introduce un email válido",
  }),
  firstName: z.string().min(1, {
    message: "Rellena este campo",
  }),
  lastName: z.string().min(1, {
    message: "Rellena este campo",
  }),
  phone: z.string().refine(validator.isMobilePhone, {
    message: "Debes ingresar un número de teléfono válido",
  }),
  password: z
    .string()
    .min(8, {
      message: "La contraseña debe tener al menos 8 caracteres",
    })
    .max(100)
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, {
      message:
        "La contraseña debe tener al menos una letra mayúscula, una minúscula, y un número",
    }),
  hasAcceptedTerms: z.boolean().refine((value) => value, {
    message: "Debes aceptar los términos y condiciones",
  }),
  hasAcceptedMarketing: z.boolean().optional(),
})

export const invitationSignUpSchema = z.object({
  firstName: z.string().min(1, {
    message: "Rellena este campo",
  }),
  lastName: z.string().min(1, {
    message: "Rellena este campo",
  }),
  password: z
    .string()
    .min(8, {
      message: "La contraseña debe tener al menos 8 caracteres",
    })
    .max(100)
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, {
      message:
        "La contraseña debe tener al menos una letra mayúscula, una minúscula, y un número",
    }),
  hasAcceptedTerms: z.boolean().refine((value) => value, {
    message: "Debes aceptar los términos y condiciones",
  }),
})

export const signInSchema = z.object({
  email: signUpSchema.shape.email,
  password: z.string().min(1, {
    message: "Rellena este campo",
  }),
})

export const verifyEmailSchema = z.object({
  code: z
    .string()
    .min(6, {
      message: "El código de verificación debe tener al menos 6 caracteres",
    })
    .max(6, {
      message: "El código de verificación debe tener máximo 6 caracteres",
    }),
})

export const checkEmailSchema = z.object({
  email: signUpSchema.shape.email,
})

export const changeEmailSchema = z.object({
  email: signUpSchema.shape.email,
})

export const resetPasswordSchema = z
  .object({
    password: signUpSchema.shape.password,
    confirmPassword: signUpSchema.shape.password,
    code: verifyEmailSchema.shape.code,
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Las contraseñas no coinciden",
    path: ["confirmPassword"],
  })

export const enterpriseOnboardingSchema = z.object({
  name: z.string().min(1, {
    message: "Rellena este campo",
  }),
  email: z.string().email({
    message: "Por favor, introduce un email válido",
  }),
  phone: z.string().refine(validator.isMobilePhone, {
    message: "Debes ingresar un número de teléfono válido",
  }),
  address: z.string().optional(),
})

export const enterpriseProfileSchema = organizationPublicMetadataSchema.extend({
  name: z.string().min(1, {
    message: "Rellena este campo",
  }),
})

export const changePasswordSchema = z
  .object({
    currentPassword: signUpSchema.shape.password,
    newPassword: signUpSchema.shape.password,
    confirmNewPassword: signUpSchema.shape.password,
    signOutOfOtherSessions: z.boolean(),
  })
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    message: "Las contraseñas no coinciden",
    path: ["confirmNewPassword"],
  })
