import { cn } from "@/lib/utils"
import { Badge } from "@/components/ui/badge"
import { Icons } from "@/components/icons"

export function LastProviderBadge({ className }: { className?: string }) {
  return (
    <Badge
      className={cn(
        "rounded-md bg-primary/10 font-light text-black",
        className
      )}
    >
      Último utilizado
      <Icons.key className="ml-2 size-4" aria-hidden="true" />
    </Badge>
  )
}
